import React from 'react'
import styled from 'styled-components';
import {Link} from 'gatsby';

// Styled components
const Button = styled(Link)`
	background: var(--blue);
	border: 2px solid transparent;
	border-radius: 4px;
	box-shadow: var(--shadow);
	color: var(--white);
	display: block;
        font-weight: normal;
	margin: 2rem auto;
	max-width: 150px;
	padding: 0.75rem 1rem;
	text-align: center;
	text-decoration: none;
	width: 100%;

	&:hover { opacity: 0.75; }
`;

// Function components
const ContactMeButton = () => (
	<Button to="/contact">Contact Me</Button>
);

export default () => (<ContactMeButton/>);
