import React from 'react';
import styled from 'styled-components';

// Styled components
const PortfolioSectionContainer = styled.article`
    display: flex;
    height: 400px;
    flex-direction: ${props => props.left ? 'row-reverse' : 'row'};
    margin: 3rem auto;

    @media (max-width: 620px) {
        flex-direction: column; 
        height: 600px;
    }
`;

const PortfolioImage = styled.img`
    border: 2px solid transparent;
    border-radius: 2px;
    filter: drop-shadow(var(--shadow));
    display: block;
    max-width: 250px;
    position: absolute;
    top: 0;
    transition: .2s;

    &:hover { transform: translateY(-5%); }

    &:first-of-type { right: 0; }
    &:nth-of-type(2) { right: 25%; top: 20%; }
    &:nth-of-type(3) { left: 0; top: -10%; }

    @media (max-width: 620px) {
        max-width: 200px;

        &:first-of-type { right: 15%; }
        &:nth-of-type(2) { right: 30%; top: 25%; }
        &:nth-of-type(3) { left: -20%; top: -25%; }
    }
`;

const PortfolioImageSection = styled.section`
    margin: 3rem;
    padding: 2rem;
    position: relative;
    width: 50%;

    @media (max-width: 620px) {
        width: 100%;
    }
`;

const PortfolioCopySection = styled.section`
    font-size: calc(18px + 0.25vw);
    height: 100%;
    margin-top: 2rem;
    max-width: calc(var(--line-length) * .75);
    padding: 1rem 0.5rem;
    width: 50%;

    @media (max-width: 620px) {
        font-size: calc(14px + 0.25vw);
        margin-top: 8rem;
        width: 100%;
    }
`;

const PortfolioCopySectionTitle = styled.strong`
    display: block;
    font-size: calc(24px + 0.25vw);

    @media (max-width: 620px) {
        font-size: calc(16px + 0.25vw);
    }
`

// Export primary component
export default props => (
    <PortfolioSectionContainer left={props.left}>
        <PortfolioImageSection>
        {props.images.map(image => {
            return <PortfolioImage src={image.src} alt={image.alt} key={image.src}/>
        })}
        </PortfolioImageSection> 
        <PortfolioCopySection>
            <PortfolioCopySectionTitle>{props.title}</PortfolioCopySectionTitle> 
            <p>{props.copy}</p>
        </PortfolioCopySection>
    </PortfolioSectionContainer>
);

PortfolioSectionContainer.defaultProps = { left: false };
