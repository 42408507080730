import React from 'react';
import styled from 'styled-components';

// Styled components
const PortfolioBannerContainer = styled.header`
  line-height: 6rem;
  margin: 3rem auto 2rem auto;

  &::after {
    background: var(--trans-black);
    content: "";
    display: block;
    height: 2px;
    width: 100%;
  }

  @media (max-width: 620px) {
    margin-bottom: 2rem;
  }

  h1, {
    margin: 0;
    padding: 0;
    text-shadow: var(--shadow);
  }
`;

// Functional components
const PortfolioBanner = () => (
  <PortfolioBannerContainer>
    <h1>What I've built in the past:</h1>
  </PortfolioBannerContainer>
);

export default () => (<PortfolioBanner/>);
