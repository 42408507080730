import React from 'react';
import Layout from './../components/Layout';
import PortfolioBanner from './../components/PortfolioBanner';
import PortfolioFooter from './../components/PortfolioFooter';
import PortfolioSection from './../components/PortfolioSection';

// Import styling components
import styled from 'styled-components';

// Styled components
const PortfolioContainer = styled.article`
    margin: auto;
    max-width: 1280px;
    padding: 0 2rem 2rem 2rem;
`;

// Images
const websiteImages = [
    {
        alt: "Hackerload",
        src: "https://images.ubben.co/hackerload-ubbenco.jpg"
    },
    {
        alt: "OwnerOperatorJobs.com",
        src: "https://images.ubben.co/owneroperatorjobs-main.jpg"
    },
    {
        alt: "BK Propery Offshore",
        src: "https://images.ubben.co/bkpropertyoffshore-ubbenco.jpg"
    },
];

const terminalImages = [
    {
        alt: "Hyper Terminal - Ubben.co 1",
        src: "https://images.ubben.co/hyper1-ubbenco.png"
    },
    {
        alt: "Hyper Terminal - Ubben.co 2",
        src: "https://images.ubben.co/hyper2-ubbenco.png"
    },
    {
        alt: "Hyper Terminal - Ubben.co 3",
        src: "https://images.ubben.co/hyper3-ubbenco.png"
    }
]

export default () => (
    <Layout pageTitle="Web Development Portfolio">
        <PortfolioContainer>
            <PortfolioBanner/>
	    <PortfolioSection images={ websiteImages } title="Custom Coded Websites" copy="I have built out full websites in HTML/CSS/JavaScript and PHP on their own, as well as have utilized frameworks and design systems such as WordPress and Bootstrap. I'd love to build out the exact website or web-application that suits your needs and am not afraid to build it from scratch."/>
            <PortfolioSection images={ terminalImages } title="Scripting &amp; Serverless Functions" copy="I have utilized PHP and Node for scraping content on web pages, API integration into already existing backend services, and enabling what would normally be server-side only API interaction from the frontend via Google Cloud Functions and JavaScript." left/>
	    <PortfolioFooter/>
        </PortfolioContainer>
    </Layout>
);
