import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import ContactMeButton from './ContactMeButton';

// Styled components
const PortfolioFooterContainer = styled.section`
  border: 2px solid var(--trans-black);
  border-radius: 2px;
  box-shadow: var(--shadow);
  margin: auto;
  max-width: var(--line-length);
  padding: 0.5rem 1rem;
`;

export default () => (
  <PortfolioFooterContainer>
    <p>I am usually always open to freelance, contract, part-time and full-time roles &mdash; so long as the compensation is comfortable.</p>
    <p>Feel free to get in touch with me if you'd like to hire me, learn more about me, give me some constructive criticism on my site, or even just to talk dev things.</p>
    <p>Want my full bio? Feel free to check out the <Link className="link" to="/about">About</Link> page.</p>
    <ContactMeButton/>
  </PortfolioFooterContainer>
);
